<app-header *ngIf="isLoggedIn && canShowHeader" [getScreenWidth]="getScreenWidth"></app-header>
<app-loading></app-loading>

<mat-sidenav-container class="example-container" [ngClass]="{'h-100': !canShowHeader}" *ngIf="isLoggedIn;else loginPage"
    hasBackdrop="false">
    <mat-sidenav #sidenav mode="{{getScreenWidth > 990 ? 'side' : 'over'}}" [opened]="isOpened">
        <mat-nav-list class="sidenav-list">
            <a mat-list-item routerLink="/dashboard" routerLinkActive="active-link">
                <mat-icon>dashboard</mat-icon>
                <span class="mt-1">Dashboard</span>
            </a>
            <a mat-list-item routerLink="/members" routerLinkActive="active-link">
                <mat-icon>people_outline</mat-icon>
                <span class="mt-1">Members</span>
            </a>
            <!-- <a mat-list-item routerLink="/cards-collection" routerLinkActive="active-link">
                <mat-icon>chrome_reader_mode</mat-icon>
                <span class="mt-1">Cards</span>
            </a> -->

            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent">
                <mat-icon>chrome_reader_mode</mat-icon>
                <span class="mt-1">Cards</span>
                <mat-icon class="menu-button ml-3" [ngClass]="{'rotated' : showSubmenu}">expand_more</mat-icon>
            </mat-list-item>

            <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="showSubmenu">
                <a mat-list-item routerLink="/discovery" routerLinkActive="active-link">
                    <span class="mt-1">Discovery</span>
                </a>
                <a mat-list-item routerLink="/upload-queue" routerLinkActive="active-link">
                    <span class="mt-1">Upload Queue</span>
                </a>
            </div>
            <a mat-list-item routerLink="/sub-profiles" routerLinkActive="active-link">
                <mat-icon>people_outline</mat-icon>
                <span class="mt-1">Sub Profiles</span>
            </a>

            <a mat-list-item routerLink="/manage-sales" routerLinkActive="active-link">
                <mat-icon>local_offer</mat-icon>
                <span class="mt-1">Manage Sales</span>
            </a>
            <a mat-list-item routerLink="/csv-upload-queue" routerLinkActive="active-link">
                <mat-icon>cloud_upload</mat-icon>
                <span class="mt-1">CSV Upload</span>
            </a>
            <!-- <span class="version">Version 1.0.1</span> -->
        </mat-nav-list>
        <span class="version-text">Version 1.6.6</span>
    </mat-sidenav>

    <mat-sidenav-content>


        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #loginPage>
    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
</ng-template>