export const environment = {
    environment: 'prod',
    webToken: '%!ng4cc355T0k3n@123%!^123590abcd',
    cardServiceDomain: 'https://cs.nextgem.com',
    userServiceDomain: 'https://us.nextgem.com',
    card360ServiceDomain: 'https://card360.nextgem.com',
    production: false,
    profileURL: 'https://pi.nextgem.com',
    imageURL: 'https://img.nextgem.com',
    baseURL: '',
};
