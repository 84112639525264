import { URLConstants } from './url-constants';

export abstract class AppConstants {
    public static readonly ACCESS_TOKEN = 'accessToken';
    public static readonly USER_DATA = 'userData';
    public static readonly REFRESH_TOKEN = 'refreshToken';
}

export abstract class HttpStatusCode {
    public static readonly UN_AUTHORIZED_ACCESS = 401;
    public static readonly FOR_BIDDEN = 403;
    public static readonly CONFLICTS = 409;
    public static readonly GATEWAY_TIME_OUT = 504;
}

export interface IUser {
    userName: string;
    password: string;
}

export interface UserData {
    username: string;
    userid: string;
    fullname: string;
    email: string;
    phone: string;
    dateofregistration: string;
    profileimagedomain: string;
}

export interface IUserFilter {
    searchText: string;
    searchBy: string;
}
export const ignoreLoaderUrls: string[] = [URLConstants.CARDS_UPLOAD_STATUS];
