import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('../app/super-admin/super-admin.module').then(m => m.SuperAdminModule),
    },
    // {
    //   path: 'admin',
    //   loadChildren: () => import('../app/super-admin/super-admin.module').then(m => m.SuperAdminModule)
    // },
    // {
    //   path: '',
    //   component: NotFoundComponent
    // },
    // {
    //   path: '**',
    //   redirectTo: ''
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
