import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor() {}

    setItem(key: string, value: any): void {
        if (typeof value === 'string') {
            localStorage.setItem(key, value);
        } else {
            try {
                const stringifiedValue = JSON.stringify(value);
                localStorage.setItem(key, stringifiedValue);
            } catch (error) {}
        }
    }

    getItem(key: string): any {
        try {
            const item = localStorage.getItem(key);

            return item;
        } catch (e) {
            return null;
        }
    }

    getParsedObject(key: string) {
        const obj = this.getItem(key);
        if (obj) {
            try {
                const parsedObj = JSON.parse(obj);
                return parsedObj;
            } catch (error) {
                return null;
            }
        }
    }

    removeItem(key: string): any {
        localStorage.removeItem(key);
    }

    clear(): void {
        localStorage.clear();
    }
    setCookie(cname: string, cvalue: any, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }
}
