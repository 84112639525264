// import clevertap from 'clevertap-web-sdk';
import { Injectable } from '@angular/core';
import * as clevertap from 'clevertap-web-sdk';

@Injectable({
    providedIn: 'root',
})
export class CleverTapService {
    constructor() {}

    init(): void {
        clevertap.init('848-47K-996Z');

        clevertap.notifications.push({
            titleText: 'Would you like to receive Push Notifications?',
            bodyText: 'We promise to only send you relevant content and give you updates on your transactions',
            okButtonText: 'Yes',
            rejectButtonText: 'No',
            okButtonColor: 'black',
            askAgainTimeInSeconds: 50,
            serviceWorkerPath: './clevertap_sw.js',
        });
    }
    start(attributes: any, userid: string): void {}

    /**
     * Maintaining Multiple User Profiles on the Same Device using OnUserLogin
     * If multiple users on the same device use your app, you can use the clevertap.onUserLogin method
     * to assign them each a unique profile to track them separately.
     */
    onUserLogin(attributes: any) {
        const userid = attributes?.userid;
        // with the exception of one of Identity, Email, or FBID
        // each of the following fields is optional

        clevertap.onUserLogin.push({
            Site: {
                Name: attributes.username, // String
                Identity: userid, // String or number
                Email: attributes?.email, // Email address of the user
                Phone: attributes?.phone, // Phone (with the country code)
                // Gender: 'M', // Can be either M or F
                DOB: new Date(), // Date of Birth. Date object
                // optional fields. controls whether the user will be sent email, push etc.
                'MSG-email': true, // Disable email notifications
                'MSG-push': true, // Enable push notifications
                'MSG-sms': true, // Enable sms notifications
                'MSG-whatsapp': true, // Enable WhatsApp notifications
            },
        });
        // console.log(clevertap.profile, 'profile');
    }
}
