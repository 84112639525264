import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@approot/core/lib/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() getScreenWidth!: any;
    userData: any = null;
    constructor(private authenticationService: AuthenticationService) {
        this.authenticationService.userStateObservable.subscribe(res => {
            this.userData = res;
        });
    }
    logOut() {
        this.authenticationService.logout(true);
    }
    toggleSideNav() {
        this.authenticationService.toggleSideNav(false);
    }
    ngOnInit(): void {}
}
