import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@approot/core/material-module';
import { LoaderInterceptor } from './core/Services/loading.interceptor';
import { LoaderService } from './core/Services/loading.service';
import { LoadingComponent } from './core/components/loading/loading.component';
import { HeaderComponent } from './core/components/header/header.component';
import { ShortNamePipe } from './core/pipes/short-name.pipe';
import { TokenInterceptor } from './core/Services/http-interceptors';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
@NgModule({
    declarations: [AppComponent, NotFoundComponent, LoadingComponent, HeaderComponent, ShortNamePipe],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        ServiceWorkerModule.register('clevertap_sw.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
