import { Component, HostListener } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AuthenticationService } from './core/lib/authentication.service';
import { CleverTapService } from './core/Services/clevertap.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    getScreenWidth: any;
    title = 'nextgem-admin-webview';
    isLoggedIn = false;
    canShowHeader = false;
    isOpened = false;
    confirmUnload!: boolean;
    @HostListener('window:resize', ['$event']) onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        const confirmUnload = (window as any).shouldConfirmUnload;

        if (confirmUnload) {
            $event.returnValue = true; // This will show the confirmation dialog
        }
    }
    constructor(
        private authenticationService: AuthenticationService,
        private clevertapService: CleverTapService,
        readonly swPush: SwPush,
    ) {
        this.clevertapService.init();
        this.authenticationService.loginStateObservable.subscribe(res => {
            this.isLoggedIn = res;
        });
        this.authenticationService.toggleStateObservable.subscribe(res => {
            this.isOpened = res;
        });
        this.authenticationService.showHeaderStateObservable.subscribe(res => {
            this.canShowHeader = res;
        });
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.getScreenWidth = window.innerWidth;
        this.swPush.notificationClicks.subscribe(({ action, notification }) => {
            // TODO: Do something in response to notification click.
            console.log(action, notification, 'angular sw push object');
        });

        window.navigator.serviceWorker.addEventListener('message', event => {
            const { data } = event;
            console.log(event, 'notification evet window object');

            if (data?.redirectURL) {
                window.location.href = data.redirectURL;
                return;
            }
        });
    }
}
