import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../lib/authentication.service';
import { AppConstants, HttpStatusCode } from '../lib/constants/common-constants';
import { LocalStorageService } from '../lib/local-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private refreshingInProgress: boolean = false;
    private accessTokenSubject: BehaviorSubject<string | any> = new BehaviorSubject<string | any>(null);

    constructor(
        private localStorageService: LocalStorageService,
        private _authService: AuthenticationService,
        private _authenticationService: AuthenticationService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.localStorageService.getItem(AppConstants.ACCESS_TOKEN);
        const userDetails = this.localStorageService.getParsedObject(AppConstants.USER_DATA);

        if (accessToken && !req.url.includes('amazonaws')) {
            let headers: any = {
                Authorization: `Bearer ${accessToken}`,
                'x-nextgem-usertype': '2',
            };
            if (!req.headers.has('x-nextgem-userid')) {
                headers['x-nextgem-userid'] = userDetails?.userid;
            }
            req = req.clone({
                setHeaders: headers,
            });
        }
        return next.handle(req).pipe(
            catchError((err: any) => {
                // in case of 401 http error
                if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.UN_AUTHORIZED_ACCESS) {
                    // get refresh tokens
                    const refreshToken = this.localStorageService.getItem(AppConstants.REFRESH_TOKEN);

                    // if there are tokens then send refresh token request
                    if (refreshToken && accessToken) {
                        return this.refreshToken(req, next);
                    }

                    // otherwise logout and redirect to login page
                    return this.logoutAndRedirect(err);
                }

                // in case of 403 http error (refresh token failed)
                if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.GATEWAY_TIME_OUT) {
                    // logout and redirect to login page
                    return this.logoutAndRedirect(err);
                }
                // if error has status neither 401 nor 403 then just return this error
                return throwError(err);
            }),
        );
    }

    private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
            return request.clone({
                setHeaders: { Authorization: `Bearer ${token}` },
            });
        }

        return request;
    }

    private logoutAndRedirect(err: HttpErrorResponse): Observable<HttpEvent<any>> {
        this._authenticationService.logout(true);
        return throwError(err);
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshingInProgress) {
            console.warn('refresh in not progress');
            this.refreshingInProgress = true;
            this.accessTokenSubject.next(null);

            return this._authService.refreshToken().pipe(
                switchMap((res: any) => {
                    this.refreshingInProgress = false;
                    this.accessTokenSubject.next(res.Token);
                    // repeat failed request with new token
                    return next.handle(this.addAuthorizationHeader(request, res.Token));
                }),
                finalize(() => {
                    this.refreshingInProgress = false;
                }),
            );
        } else {
            console.warn('refresh in progress');
            // wait while getting new token
            return this.accessTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    // repeat failed request with new token
                    return next.handle(this.addAuthorizationHeader(request, token));
                }),
            );
        }
    }
}
