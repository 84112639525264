
      <header>
  <!-- <img src="/assets/images/logo.png" class="web-img d-block" *ngIf="getScreenWidth > 990;else smallScreen">
  <span></span> -->
  <!-- <ng-template #smallScreen> -->

      <div class="d-flex">
          <button mat-icon-button (click)="toggleSideNav()" class="mr-3">
              <mat-icon>menu</mat-icon>
          </button>
          <img src="/assets/images/logo.png" class=" d-block" [ngClass]="getScreenWidth > 990 ? 'web-img' : 'mobile-img'">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="70" height="50" viewBox="0 0 70 50">
          <g fill="none" fill-rule="evenodd">
              <g>
                  <g>
                      <g transform="translate(-16 -116) translate(0 98) translate(16 18)">
                          <rect width="50" height="50" x="1" fill="#14111F" rx="8"/>
                          <g fill="#00D8FF">
                              <g>
                                  <path d="M35.088 3.732L35.088 2.902 33.364 2.902 32.64 3.732 35.088 3.732M41.826 1.451h-3.847c-.67 0-1.055.415-1.055 1.138v2.594h.93V2.717c0-.224.063-.453.53-.453h1.07v2.919h1.052v-2.92h1.07c.348 0 .517.149.517.454v2.466h.951V2.67c0-.81-.41-1.22-1.218-1.22M28.065 2.902L27.852 2.902 27.132 3.654 29.154 3.654 29.154 5.183 29.988 5.183 29.988 2.902 28.065 2.902" transform="translate(0 17) translate(3.264 5.183)"/>
                                  <path d="M25.092 3.317c0 1.047.824 1.866 1.875 1.866h1.623v-.734h-1.395c-.612 0-1.148-.529-1.148-1.132 0-.603.536-1.132 1.148-1.132h2.793v-.734h-3.02c-1.052 0-1.876.82-1.876 1.866M30.804 2.67v1.293c0 .73.454 1.22 1.13 1.22h3.97v-.734h-3.556c-.265 0-.48-.203-.48-.453V2.645c0-.257.215-.46.48-.46h3.556v-.734h-3.973c-.664 0-1.127.502-1.127 1.22M11.515 2.917v.809H9.038l.755-.809h1.722zm.882-.706h-3.51c-.238 0-.433.192-.433.428V4c0 .233.195.422.433.422h3.51v.808H8.482c-.68 0-1.139-.507-1.139-1.262V2.666c0-.744.468-1.263 1.137-1.263h3.917v.808zm-5.832 2.35c0 .056-.005.11-.013.163l-.006.029-.01.04-.003.013c-.07.239-.255.38-.55.423-.068.01-.133.015-.193.015-.268 0-.452-.088-.565-.17l-2.7-2.31v2.465H1.51V1.975c0-.382.217-.584.629-.584.364 0 .567.169.576.176L5.55 3.998V1.391h1.014v3.17zm7.664-.612l.6-.633-1.814-1.913h1.375l.01.011 1.114 1.175.321-.34L17.952 0H1.936C.87 0 0 .846 0 1.885V4.75c0 1.04.869 1.885 1.936 1.885h9.72L14.23 3.95z" transform="translate(0 17) translate(3.264 5.183)"/>
                                  <path d="M44.156 4.75c0 .87-.732 1.579-1.633 1.579H24.13V.305h18.393c.9 0 1.633.71 1.633 1.58V4.75zM23.416 2.21h-2.127v3.024h-1.02V2.21h-2.31l.754-.808h4.703v.808zM42.523 0H19.245l-3.156 3.316 1.82 1.913h-1.384l-.01-.01-1.12-1.176-2.543 2.591h29.671c1.075 0 1.949-.845 1.949-1.885V1.885C44.472.845 43.598 0 42.523 0z" transform="translate(0 17) translate(3.264 5.183)"/>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg> -->
      </div>
  <!-- </ng-template> -->
  
 
 <div class=" d-flex align-items-center">
 
<button mat-button [matMenuTriggerFor]="menu" *ngIf="userData" class="text-capitalize mr-2">
    <span class="logout-icon text-uppercase">{{userData.username | shortName}}</span>
     <span> {{userData.username}}</span></button>

<!-- #enddocregion mat-menu-trigger-for -->
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="logOut()"><mat-icon>power_settings_new</mat-icon> Logout</button>
</mat-menu>

 </div>
</header>