import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CardService } from '../Services/admin-service';
import { AppConstants, IUser } from './constants/common-constants';
import { URLConstants } from './constants/url-constants';
import { LocalStorageService } from './local-storage.service';
import { CleverTapService } from '../Services/clevertap.service';

const enum UserCredentials {
    userName = 'admin',
    password = 'admin',
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    loginState!: BehaviorSubject<boolean>;
    loginStateObservable!: Observable<boolean>;
    userState!: BehaviorSubject<any>;
    userStateObservable!: Observable<any>;
    toggleState!: BehaviorSubject<boolean>;
    toggleStateObservable!: Observable<boolean>;
    showHeaderState!: BehaviorSubject<boolean>;
    showHeaderStateObservable!: Observable<boolean>;
    masterData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private _router: Router,
        private cardService: CardService,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        private clevertapService: CleverTapService,
    ) {
        this.loginState = new BehaviorSubject<boolean>(this.isLoggedIn());
        this.loginStateObservable = this.loginState.asObservable();

        this.userState = new BehaviorSubject<any>(this.getUserInformation());
        this.userStateObservable = this.userState.asObservable();

        this.toggleState = new BehaviorSubject<boolean>(false);
        this.toggleStateObservable = this.toggleState.asObservable();

        this.showHeaderState = new BehaviorSubject<boolean>(true);
        this.showHeaderStateObservable = this.showHeaderState.asObservable();
        if (!this.masterData.getValue()) {
            this.getMasterDataOfCards();
        }
    }

    isLoggedIn() {
        const USER_TOKEN = this.localStorageService.getItem(AppConstants.ACCESS_TOKEN);
        return !!USER_TOKEN;
    }

    toggleSideNav(isOpen: boolean) {
        const IS_OPENED = isOpen || !this.toggleState.value;
        this.toggleState.next(IS_OPENED);
    }
    getUserInformation() {
        const USER_INFORMATION = this.localStorageService.getParsedObject(AppConstants.USER_DATA);
        return USER_INFORMATION;
    }
    async getMasterDataOfCards() {
        let details = this.masterData.getValue();
        const userid = this.userState.value?.userid;
        if (!details) {
            const headers = {
                'x-nextgem-userid': userid,
            };
            const options = { headers: headers };
            const res: any = await this.http
                .get(`${environment.cardServiceDomain}/${URLConstants.GET_CARDS_MASTER_DATA}`, options)
                .toPromise();
            details = res?.data;
            this.masterData.next(details);
        }
        return details;
    }
    logout(redirect: boolean) {
        const TOKEN = this.localStorageService.getItem(AppConstants.ACCESS_TOKEN);
        if (TOKEN) {
            this.http.post(`${environment.userServiceDomain}/${URLConstants.ADMIN_LOGOUT}`, {}).subscribe(
                (res: any) => {},
                err => {},
            );
        }
        this.localStorageService.removeItem(AppConstants.ACCESS_TOKEN);
        this.localStorageService.removeItem(AppConstants.USER_DATA);
        this.loginState.next(false);
        this.userState.next(null);

        if (redirect) {
            this._router.navigate(['login']);
        }
    }

    login(user: IUser) {
        return new Promise((resolve, reject) => {
            // let response: any;
            const payload = {
                email: user.userName,
                password: user.password,
            };
            // const headers = {
            //     'x-nextgem-token': environment.nextgemToken,
            // };
            // const options = { headers: headers };
            this.http.post(`${environment.userServiceDomain}/${URLConstants.ADMIN_LOGIN}`, payload).subscribe(
                (res: any) => {
                    // console.log(res, 'success');
                    if (res.data.status) {
                        this.setToken(AppConstants.ACCESS_TOKEN, res.data.token);
                        this.localStorageService.setItem(AppConstants.USER_DATA, res.data);
                        // response = res.data;
                        this.clevertapService.onUserLogin(res?.data);
                        this.loginState.next(true);
                        this.userState.next(res.data);
                    }

                    resolve(res.data);
                },
                err => {
                    reject(err);
                },
            );

            // if (user.password === UserCredentials.password) {
            //     const data: any = { status: true, ...user, ...{ username: user.userName } };
            //     this.setToken(AppConstants.ACCESS_TOKEN, 'token');
            //     this.localStorageService.setItem(AppConstants.USER_DATA, data);
            //     // response = res.data;
            //     this.loginState.next(true);
            //     this.userState.next(data);
            //     resolve(data);
            // } else {
            // }
        });
    }

    refreshToken(): Observable<any> {
        const REFRESH_TOKEN = this.localStorageService.getItem(AppConstants.REFRESH_TOKEN);
        const ACCESS_TOKEN = this.localStorageService.getItem(AppConstants.ACCESS_TOKEN);
        return this.cardService
            .post(environment.userServiceDomain + `User/refresh-token`, { REFRESH_TOKEN, ACCESS_TOKEN })
            .pipe(
                tap((response: any) => {
                    if (response || response.hasOwnProperty('Token') || response['Token']) {
                        this.setToken(AppConstants.ACCESS_TOKEN, response['Token']);
                    }
                }),
            );
    }

    private setToken(key: string, token: string): void {
        this.localStorageService.setItem(key, token);
    }
}
