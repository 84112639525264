import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CardService {
    private searchText: string = '';
    private selectedFilters: any = null;
    constructor(private http: HttpClient) {}

    get<T>(url: string) {
        return this.http.get<T>(url);
    }

    get discoverySearchText() {
        return this.searchText;
    }
    set discoverySearchText(text: string) {
        this.searchText = text;
    }

    get discoveryFilters() {
        return this.selectedFilters;
    }
    set discoveryFilters(filters: any) {
        this.selectedFilters = filters;
    }

    post(url: string, data: any) {
        return this.http.post(url, data);
    }
}
