import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAAsdIyc3IFmP1U70YmtlflUKySD43DHRo',
    authDomain: 'nextgem-web-app.firebaseapp.com',
    projectId: 'nextgem-web-app',
    storageBucket: 'nextgem-web-app.appspot.com',
    messagingSenderId: '102069363849',
    appId: '1:102069363849:web:75fa7fd82a70085c46d258',
    measurementId: 'G-ZJTEFG2TN8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err))
    .then(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/clevertap_sw.js')
                .then(function (registration) {
                    console.log('CleverTap Service Worker registered with scope:', registration.scope);
                })
                .catch(function (error) {
                    console.log('CleverTap Service Worker registration failed:', error);
                });
        }
    });
