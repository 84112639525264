export enum URLConstants {
    ADMIN_LOGIN = 'admin/login',
    ADMIN_LOGOUT = 'admin/logout',
    SEARCH_USER = 'user/search',
    getCards = '/assets/sample-data/cards.json',
    REMOVE_USER = 'user/remove',
    UPDATE_USER = 'update/user/details',
    UPDATE_USER_NAME = 'user/update',
    CREATE_MERCHANT_ACCOUNT = 'create/express/merchant',
    USER_CHECK = 'usercheck',
    ADD_USER = '',
    GET_USER_BY_ID = 'user',
    GET_USER_BY_NAME = 'profile',
    DISCOVERY_SEARCH = 'discoversearch',
    CARDS_COLLECTION = 'cards/collection/1',
    PRE_UPLOAD_CARDS_PROCESS = 'app/data',
    CARDS_UPLOAD_STATUS = 'multicardprocessor',
    USER_CARDS_UPLOAD_STATUS = 'user/multicardprocessor',
    DELETE_CARD_FROM_QUEUE = 'cardprocessor/update', // cardid status =14
    SUGGEST_PLAYER_NAME_OR_SETNAME = 'es/suggest',
    ADD_TO_COLLECTION = 'cards',
    CARD_DETAILS = 'carddetails/web/card',
    UPDATE_CARD_DETAILS = 'carddetails',
    CREATE_PAYMENT_LINK = 'create/product/paymentlink',
    UPDATE_SOLD_STATUS = 'update/soldstatus', // APPEND NG NUMBER
    DELETE_CARD = 'card/delete',
    GENERATE_PRE_SIGNED_URL = 'get/signedurl',
    GET_CARDS_MASTER_DATA = 'cards/masterdata',
    LIST_ALL_SUB_PROFIELS = 'all/sub/profile',
    LIST_ALL_SUB_PROFIELS_CARDS = 'get/subprofile/cards',
    SUB_PROFILE_CARD_DETAILS = 'get/subprofile/carddetails',
    SUBMIT_SUB_PROFILE_CARD = 'update/subprofile/card',
    DISCOVERY_VIEW = 'discovery/view',
    GET_CARD_OFFERS_LIST = 'get/card/offer/by/status',
    UPDATE_OFFER = 'update/card/offer',
    UPDATE_PAYMENT_DETAILS = 'add/external/offer/paymentdetails',
    OFFER_HISTORY = 'get/offer/history/offerid',
    GET_OFFER_BY_ID = 'get/card/offer/by/id', // send offerid as url params
    UPDATE_SHIPPING_ADDRESS = 'edit/shipping/address/id',
    APPROVE_INTERNAL_OFFER = 'approve/card/offer',
    GET_SUB_PROFILE_DATA = 'get/sub/profile',
    RELEASE_CARD_OFFER = 'release/card/offer',
    PAY_OUT_TO_SELLER = 'create/offer/payout',
    UPDATE_SHIPMENT_STATUS = 'update/shippment/status',
    GET_PAY_OUT_STATUS = 'get/offer/payout/status',
    GET_FIXED_PRICE_DEALS = 'get/fixedprice/offer',
    TOGGLE_FEATURE_FLAG_FOR_USERS = 'user/featureflag',
    RESET_STATUS_ON_EXPIRY = 'admin/reset/cardoffer/expiry',
    SEARCH_HISTORY = 'cards/searchhistory',
    REMOVE_SEARCH_HISTORY = 'cards/searchhistory/delete', // APPEND ID AS ROUTE PARAMS
    GET_CSV_UPLOAD_QUEUE = 'get/subprof/stage/csv/list',
    GET_CARD_STATUS_BY_CSV = 'get/subprof/stage/cards',
    CSV_BULK_IMPORT = 'subprofile/card/bulk/import',
    OFFER_APP_FEE_THRESHOLD_VALUES = 'get/appfee/thresholdvalues',
    SUPPORT_UPDATE_NOTIFICATION = 'send/notification/supportupdate',
    UN_PUBLISH_SUB_CARDS = 'unpublish/subprofile/cards',
}
